<template>
    <div>
        Klik hieronder om je video te importeren

        <button class="button" @click.prevent="importVideo">
            IMPORTEER
        </button>
    </div>
</template>

<script>
import { importVideo } from "@/helpers/api/ImportHelper"
import messages from "@/helpers/storage/MessageHelper";

export default {
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    methods: {
        importVideo() {
            importVideo(this.uuid)
                .then(match => {
                    this.$router.push({ name: "match.show", params: { id: match.id } })
                })
                .catch(err => {
                    messages.error(this.t('messages.something_went_wrong', { error: err.message }));
                })
        }
    }
}
</script>